.Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button {
  flex: 0 0 auto;
  margin: 3px;
  width: 42px;
  height: 42px;
  border-radius: 3px;
}

.Header {
  text-align: center;
  flex: 1 1 auto;
  font-weight: 300;
}

.Loader {
  width: 100%;
  height: 36px;
}
