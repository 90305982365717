.Container {
  /* Aspect ratio hack */
  position: relative;
  width: 100%;
  padding-top: 80%;
}

.Diagram {
  position: absolute;
  top: 0;
  left: 0;
}

.Caption {
  padding: 0.2rem;
  margin: 0.2rem;
  border: solid 1px  var(--color-neutral);
  border-radius: 3px;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
}

.Tooltip {
  position: absolute;
  background-color: var(--color-white);
  border: solid 1px var(--color-light);
  color: var(--color-dark);
  padding: 0.5rem;
  box-shadow: 1px 1px 3px #9999;
  border-radius: 3px;
}

.TooltipHeader {
  margin: 0;
  padding: 0 0 0.5rem 0;
}

.TooltipData {
  font-weight: 500;
}
