.Test {
  width: 50%;
  height: 50%;
}

.Container {
  font-size: 1rem;
  font-weight: 700;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(6, 1fr);
  align-items: start;
  grid-template-areas:
    "sunrise sunrise sunrise temp  temp  temp"
    "sunset  sunset  sunset  feelt feelt feelt"
    "clouds  clouds  wind    wind  wind  wind"
    "desc    desc    desc    desc  desc  logo ";
}

.Sunrise {
  color: var(--color-primary);
  grid-area: sunrise;
  justify-self: start;
  transition: color 250ms;
}

.Sunset {
  color: var(--color-primary);
  grid-area: sunset;
  justify-self: start;
  transition: color 250ms;
}

.Temp {
  grid-area: temp;
  justify-self: end;
  font-size: 1.25em;
  transition: color 250ms;
}

.FeelTemp {
  grid-area: feelt;
  align-self: start;
  justify-self: end;
  font-size: 0.8em;
  transition: color 250ms;
}

.Clouds {
  grid-area: clouds;
  justify-self: start;
  transition: color 250ms;
}

.Wind {
  grid-area: wind;
  justify-self: end;
  transition: color 250ms;
}

.WindArrow {
  display: inline-block;
  padding: 0;
  transition: transform 250ms;
}

.Description {
  grid-area: desc;
  justify-self: start;
}

.Description p {
  margin: 0 0.2rem;
}

.Logo {
  grid-area: logo;
  justify-self: end;
  align-self: start;
  width: 3rem;
}
