.WheelSvg {
  fill: transparent;
  stroke-width: 5;

  /*
    Stroke-dasharray lenght value depends on radius lenght (20)
    dash/gap lenght = 2 * 3.14 * 20 / 4
  */
  stroke-dasharray: 31.4;
  stroke-linecap: round;
  transform-origin: center;
  animation: spin 575ms infinite linear;
}

.PrimaryColor {
  stroke: var(--color-primary);
}

.SecondaryColor {
  stroke: var(--color-white);
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
