.Container {
  width: 100%;
  border-bottom: solid 1px var(--color-neutral);
}

.Header {
  font-size: 1.2rem;
  margin: 0.1rem 0.2rem;
  width: 100%;
  font-weight: 200;
  letter-spacing: 0.1rem;
}
