.Container {
  --map-container-aspect-ratio: calc(100 / 131.115);
  --map-container-padding: 1.5rem;

  position: relative;
}

.KeepAspectRatio {
  /* Aspect ratio hack */
  position: relative;
  padding: 0;
  width: 100%;
  padding-top: calc(1 / var(--map-container-aspect-ratio) * 100%);
}

.Image {
  position: absolute;
  top: 0;
  left: 0;
}

.Point {
  stroke-width: 0.2%;
  fill: var(--color-danger);
  stroke: var(--color-light);
}

.Tooltip {
  position: absolute;
  background-color: var(--color-white);
  border: 1px solid var(--color-light);
  box-shadow: 0 5px 10px #5555;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  width: 16rem;
}
