.Backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0.3) 50%, rgba(0, 0, 0, 0.6) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.09), rgba(0, 255, 0, 0.04), rgba(0, 0, 255, 0.09));
  background-size: 100% 2px, 3px 100%;
  backdrop-filter: blur(3px);
  opacity: 0;
  animation: show 0.2s forwards;
}

@keyframes show {
  100% {
    opacity: 1;
  }
}
