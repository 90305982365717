@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap');

:root {
  /*
  * COLORS
  * Palette: https://www.canva.com/colors/color-palettes/island-cove/
  */
  --color-dark: #4e535d;
  --color-light: rgba(79, 200, 209, 0.2);
  --color-white: #fff;
  --color-neutral: #939cad;
  --color-primary: #4fc8d1;
  --color-danger: #ff2768;
  --color-sea: #cef7fa;
  --color-land: #ecf9d8;

  /*
  * DIMENSIONS
  */
  --width-app-max: 60rem;
  --height-app-max: 60rem;
  --height-app-min: 30rem;
}

body {
  color: var(--color-dark);
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 300;
  font-family:
    'Work Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

h2 {
  font-size: 1.5rem;
  margin: 0;
}

h3 {
  font-size: 1.2rem;
  margin: 0;
}

p {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  padding: 0;
}
