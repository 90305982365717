.Container {
  position: relative;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin: 0.2rem;
  padding: 0.5rem 2.2rem 0.2rem 0.5rem;
  border-radius: 5px;
  box-shadow: 0 5px 10px #5555;
  background-color: #fff;
  transform-origin: top center;
  animation-fill-mode: forwards;
}

.open {
  flex-grow: 1;
  animation-name: open;
}

.close {
  animation-name: close;
}

.Highlight {
  color: var(--color-dark);
  border: solid 2px var(--color-primary);
  background-color: rgba(207, 241, 244, 0.7);
}

.Danger {
  color: var(--color-dark);
  border: solid 2px var(--color-danger);
  background-color: rgba(246, 218, 226, 0.7);
}

.Default {
  color: var(--color-dark);
  border: solid 2px var(--color-neutral);
  background-color: rgba(239, 239, 241, 0.7);
}

.Container > strong::after {
  content: " ";
}

.Button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.4rem;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
}

@keyframes open {
  from {
    transform: scale(0, 0);
    opacity: 0;
  }

  20% {
    transform: scale(1, 0.2);
    opacity: 1;
  }

  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes close {
  from {
    transform: scale(1, 1);
    opacity: 1;
  }

  80% {
    transform: scale(1, 0.2);
    opacity: 1;
  }

  to {
    transform: scale(0, 0);
    opacity: 0;
  }
}
