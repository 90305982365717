.Container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Inner {
  --margin: 5px;

  position: relative;
  overflow: hidden;
  margin: var(--margin);
  box-shadow: 2px 2px 15px -7px #9999;
  background-color: var(--color-light);
  height: calc(100% - 2 * var(--margin));
  width: calc(100% - 2 * var(--margin));
}

.Inner::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background:
    linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );

  /* Adding animation */
  animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
