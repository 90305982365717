.Input {
  position: relative;
  height: 1.5rem;
}

.Search {
  position: absolute;
  inset: 0;
  width: 100%;
  border: none;
  border-bottom: solid 1px var(--color-neutral);
  color: var(--color-dark);
  caret-color: var(--color-dark);
  padding: 0.3rem 0.5rem;
  outline: none;
  transition: border-bottom 250ms ease;
}

.Search:focus {
  border-bottom: solid 2px var(--color-primary);
}

.Search::placeholder {
  color: var(--color-neutral);
}

.Button,
.Spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.Spinner {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.2rem 0;
}

.Button {
  border: none;
  text-decoration: none;
  padding: 0.3rem 0.2rem;
  border-radius: 3px;
  color: var(--color-primary);
  background-color: transparent;
  transition: background 250ms ease, color 250ms ease, transform 100ms ease;
}

.Button:active {
  transform: scale(0.85);
}

.Button:disabled {
  color: var(--color-neutral);
  opacity: 0.5;
}

.Button:focus,
.Button:hover:not(:disabled) {
  border: none;
  outline: none;
  background-color: var(--color-primary);
  color: var(--color-white);
}

.Results {
  background-color: var(--color-white);
  margin: 0;
  padding: 0;
  position: absolute;
  right: -0.5rem;
  width: 300%;
  max-width: min(calc(var(--width-app-max) * 3 / 4), 75vw);
  top: 1.8rem;
  box-shadow: 0 2px 3px var(--color-neutral);
  border-radius: 3px 0 3px 3px;
  overflow: hidden;
  transition: transform 250ms ease;
  transform-origin: right -1rem;
}

.Hidden {
  transform: scale(0);
}

.ResultItem {
  list-style: none;
  margin: 0;
  padding: 0.5rem 0.5rem;
  transition: color 250ms ease, background-color 250ms ease;
  cursor: pointer;
}

.ResultItem:focus {
  outline: none;
}

.SelectedItem {
  color: var(--color-white);
  background-color: var(--color-primary);
}

.ResultIcon {
  padding-right: 0.5rem;
  color: var(--color-primary);
  transition: color 250ms ease, background-color 250ms ease;
}

.SelectedItem .ResultIcon {
  color: var(--color-white);
  background-color: var(--color-primary);
}
