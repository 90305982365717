.Container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 500;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 250ms ease-out;
}

.Hide {
  opacity: 0;
}

.Modal {
  max-width: 90vw;
  margin: 2rem;
  flex: 0 1 auto;
  border: 1px solid var(--color-neutral);
  box-shadow: 1px 1px 3px var(--color-dark);
  background-color: var(--color-white);
  border-radius: 3px;
  padding: 32px;
  transition: transform 350ms cubic-bezier(0.27, 1.02, 0.99, 1.15);
  position: relative;
}

.TranslateUp {
  transform: translateY(-100vh);
}

.CloseBtn {
  position: absolute;
  top: 2px;
  right: 2px;
  margin: 0;
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  outline: none;
  border: none;
  background-color: var(--color-white);
  transition: background-color 250ms ease, transform 100ms ease;
}

.CloseBtn:active {
  transform: scale(0.8);
}

.BtnContent {
  position: relative;
  margin: auto;
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: transparent;
}

.BtnContent::before,
.BtnContent::after {
  display: block;
  width: 24px;
  height: 2px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--color-danger);
  transition: background-color 250ms ease;
}

.BtnContent::before {
  transform: translate(-50%, -50%) rotate(135deg);
}

.BtnContent::after {
  transform: translate(-50%, -50%) rotate(-135deg);
}

.CloseBtn:hover {
  background-color: var(--color-danger);
}

.CloseBtn:hover .BtnContent::before,
.CloseBtn:hover .BtnContent::after {
  background-color: var(--color-white);
}

@keyframes show {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-100vh); }
  to { transform: translateY(0); }
}
