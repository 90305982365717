.ListItem {
  padding: 0.1rem;
  border: solid 1px #fff;
  border-radius: 3px;
  transition: all 250ms ease;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ItemSelected {
  border: solid 1px var(--color-primary);
}

.ItemSelected:focus {
  outline: none;
}

.Button {
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  text-decoration: none;
  padding: 0.4rem;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-color: transparent;
  transition: background 250ms ease, color 250ms ease, transform 250ms ease;
}

.ButtonActive {
  color: var(--color-primary);
}

.ButtonDefault {
  color: var(--color-neutral);
}

.ButtonDanger {
  color: var(--color-danger);
}

.Button.Active,
.Button:active {
  transform: scale(0.8);
}

.Button:focus,
.Button:hover:not(:disabled) {
  border: none;
  outline: none;
  color: var(--color-white);
}

.ButtonActive:focus,
.ButtonActive:hover:not(:disabled) {
  background-color: var(--color-primary);
}

.ButtonDefault:focus,
.ButtonDefault:hover:not(:disabled) {
  background-color: var(--color-neutral);
}

.ButtonDanger:focus,
.ButtonDanger:hover:not(:disabled) {
  background-color: var(--color-danger);
}

.Text {
  display: inline-block;
  color: var(--color-dark);
  padding: 0.4rem 0.4rem 0.2rem;
  overflow-wrap: break-word;
  overflow: auto;
  flex: 1 1 auto;
}

.Spinner {
  width: 28px;
  height: 28px;
}
