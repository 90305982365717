@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap);
:root {
  /*
  * COLORS
  * Palette: https://www.canva.com/colors/color-palettes/island-cove/
  */
  --color-dark: #4e535d;
  --color-light: rgba(79, 200, 209, 0.2);
  --color-white: #fff;
  --color-neutral: #939cad;
  --color-primary: #4fc8d1;
  --color-danger: #ff2768;
  --color-sea: #cef7fa;
  --color-land: #ecf9d8;

  /*
  * DIMENSIONS
  */
  --width-app-max: 60rem;
  --height-app-max: 60rem;
  --height-app-min: 30rem;
}

body {
  color: #4e535d;
  color: var(--color-dark);
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 300;
  font-family:
    'Work Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

h2 {
  font-size: 1.5rem;
  margin: 0;
}

h3 {
  font-size: 1.2rem;
  margin: 0;
}

p {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

.BasicButton_Reseters__nGfNv {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  background-color: inherit;
  transition: transform 250ms;
}

.MutableButton_MutableButton__3dGvo {
  box-shadow: 1px 1px 3px #9999;
  background-color: #fffe;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  cursor: pointer;
}

.MutableButton_MutableButton__3dGvo:focus {
  box-shadow: 1px 1px 3px var(--color-primary);
}

.MutableButton_MutableButton__3dGvo.MutableButton_Disabled__1YLkL {
  cursor: not-allowed;
}

.MutableButton_MutableButton__3dGvo.MutableButton_Active__cCef3,
.MutableButton_MutableButton__3dGvo:active:not(.MutableButton_Disabled__1YLkL) {
  box-shadow: inset 1px 1px #999;
}

.MutableButton_MutableButtonContent__2YSp8 {
  position: relative;
}

.MutableButton_MutableButtonContent__2YSp8,
.MutableButton_MutableButtonContent__2YSp8::before,
.MutableButton_MutableButtonContent__2YSp8::after {
  display: inline-block;
  width: 50%;
  height: 2px;
  transition: all 0.5s ease-out;
}

.MutableButton_MutableButtonContent__2YSp8::before,
.MutableButton_MutableButtonContent__2YSp8::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.MutableButton_Highlight__3VKrn,
.MutableButton_Highlight__3VKrn::before,
.MutableButton_Highlight__3VKrn::after {
  background-color: var(--color-primary);
}

.MutableButton_Danger__1u0u2,
.MutableButton_Danger__1u0u2::before,
.MutableButton_Danger__1u0u2::after {
  background-color: var(--color-danger);
}

.MutableButton_MutableButtonContent__2YSp8.MutableButton_Disabled__1YLkL,
.MutableButton_MutableButtonContent__2YSp8.MutableButton_Disabled__1YLkL::before,
.MutableButton_MutableButtonContent__2YSp8.MutableButton_Disabled__1YLkL::after {
  background-color: var(--color-neutral);
}

/* Burger Button */

.MutableButton_BurgerButton__2XFy6::before {
  top: -8px;
}

.MutableButton_BurgerButton__2XFy6::after {
  top: 8px;
}

.MutableButton_MutableButton__3dGvo:focus .MutableButton_BurgerButton__2XFy6::before,
.MutableButton_MutableButton__3dGvo:hover .MutableButton_BurgerButton__2XFy6::before {
  top: -12px;
}

.MutableButton_MutableButton__3dGvo:focus .MutableButton_BurgerButton__2XFy6::after,
.MutableButton_MutableButton__3dGvo:hover .MutableButton_BurgerButton__2XFy6::after {
  top: 12px;
}

.MutableButton_MutableButton__3dGvo:hover .MutableButton_Disabled__1YLkL.MutableButton_BurgerButton__2XFy6::before {
  top: -8px;
}

.MutableButton_MutableButton__3dGvo:hover .MutableButton_Disabled__1YLkL.MutableButton_BurgerButton__2XFy6::after {
  top: 8px;
}

/* Close Button */
.MutableButton_CloseButton__2A3Hf,
.MutableButton_CloseButton__2A3Hf.MutableButton_Disabled__1YLkL {
  background-color: transparent;
}

.MutableButton_CloseButton__2A3Hf::before {
  top: 0;
  transform: rotate(135deg);
}

.MutableButton_CloseButton__2A3Hf::after {
  top: 0;
  transform: rotate(-135deg);
}

.MutableButton_MutableButton__3dGvo:focus .MutableButton_CloseButton__2A3Hf,
.MutableButton_MutableButton__3dGvo:hover .MutableButton_CloseButton__2A3Hf {
  transform: scale(1.2);
}

.MutableButton_MutableButton__3dGvo:hover .MutableButton_Disabled__1YLkL.MutableButton_CloseButton__2A3Hf {
  transform: scale(1);
}

/* Left Button */
.MutableButton_LeftButton__zTTNa,
.MutableButton_LeftButton__zTTNa.MutableButton_Disabled__1YLkL {
  background-color: transparent;
}

.MutableButton_LeftButton__zTTNa::before {
  top: 0;
  transform: rotate(45deg);
  transform-origin: left;
}

.MutableButton_LeftButton__zTTNa::after {
  top: 0;
  transform: rotate(-45deg);
  transform-origin: left;
}

.MutableButton_MutableButton__3dGvo:focus .MutableButton_LeftButton__zTTNa,
.MutableButton_MutableButton__3dGvo:hover .MutableButton_LeftButton__zTTNa {
  transform: scale(1.2);
}

.MutableButton_MutableButton__3dGvo:hover .MutableButton_Disabled__1YLkL.MutableButton_LeftButton__zTTNa {
  transform: scale(1);
}

/* Right Button */
.MutableButton_RightButton__1oxCt,
.MutableButton_RightButton__1oxCt.MutableButton_Disabled__1YLkL {
  background-color: transparent;
}

.MutableButton_RightButton__1oxCt::before {
  top: 0;
  transform: rotate(45deg);
  transform-origin: right;
}

.MutableButton_RightButton__1oxCt::after {
  top: 0;
  transform: rotate(-45deg);
  transform-origin: right;
}

.MutableButton_MutableButton__3dGvo:focus .MutableButton_RightButton__1oxCt,
.MutableButton_MutableButton__3dGvo:hover .MutableButton_RightButton__1oxCt {
  transform: scale(1.2);
}

.MutableButton_MutableButton__3dGvo:hover .MutableButton_Disabled__1YLkL.MutableButton_RightButton__1oxCt {
  transform: scale(1);
}

.ScreenLoader_Container__1FUhx {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScreenLoader_Inner__2J72p {
  --margin: 5px;

  position: relative;
  overflow: hidden;
  margin: var(--margin);
  box-shadow: 2px 2px 15px -7px #9999;
  background-color: var(--color-light);
  height: calc(100% - 2 * var(--margin));
  width: calc(100% - 2 * var(--margin));
}

.ScreenLoader_Inner__2J72p::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background:
    linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );

  /* Adding animation */
  animation: ScreenLoader_loading__3bFJL 0.8s infinite;
}

/* Loading Animation */
@keyframes ScreenLoader_loading__3bFJL {
  100% {
    transform: translateX(100%);
  }
}

.DateHandler_Container__3Q_V2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DateHandler_Button__36zpr {
  flex: 0 0 auto;
  margin: 3px;
  width: 42px;
  height: 42px;
  border-radius: 3px;
}

.DateHandler_Header__2CslZ {
  text-align: center;
  flex: 1 1 auto;
  font-weight: 300;
}

.DateHandler_Loader__CNMEE {
  width: 100%;
  height: 36px;
}

.Map_Container__1atR2 {
  --map-container-aspect-ratio: calc(100 / 131.115);
  --map-container-padding: 1.5rem;

  position: relative;
}

.Map_KeepAspectRatio__2bRRD {
  /* Aspect ratio hack */
  position: relative;
  padding: 0;
  width: 100%;
  padding-top: calc(1 / var(--map-container-aspect-ratio) * 100%);
}

.Map_Image__2EzOK {
  position: absolute;
  top: 0;
  left: 0;
}

.Map_Point__3XaLz {
  stroke-width: 0.2%;
  fill: var(--color-danger);
  stroke: var(--color-light);
}

.Map_Tooltip__2MfIM {
  position: absolute;
  background-color: var(--color-white);
  border: 1px solid var(--color-light);
  box-shadow: 0 5px 10px #5555;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  width: 16rem;
}

.Spinner_WheelSvg__1jMaA {
  fill: transparent;
  stroke-width: 5;

  /*
    Stroke-dasharray lenght value depends on radius lenght (20)
    dash/gap lenght = 2 * 3.14 * 20 / 4
  */
  stroke-dasharray: 31.4;
  stroke-linecap: round;
  transform-origin: center;
  animation: Spinner_spin__1L1Ft 575ms infinite linear;
}

.Spinner_PrimaryColor__EbwMQ {
  stroke: var(--color-primary);
}

.Spinner_SecondaryColor__3wSDz {
  stroke: var(--color-white);
}

@keyframes Spinner_spin__1L1Ft {
  100% {
    transform: rotate(360deg);
  }
}

.H2_Container__16p0p {
  width: 100%;
  border-bottom: solid 1px var(--color-neutral);
}

.H2_Header__MIy8u {
  font-size: 1.5rem;
  margin: 0.2rem 0.3rem;
  width: 100%;
  font-weight: 200;
  letter-spacing: 0.15rem;
}

.H3_Container__xbX3v {
  width: 100%;
  border-bottom: solid 1px var(--color-neutral);
}

.H3_Header__3MH1q {
  font-size: 1.2rem;
  margin: 0.1rem 0.2rem;
  width: 100%;
  font-weight: 200;
  letter-spacing: 0.1rem;
}

.DayInfo_Test__2NyAe {
  width: 50%;
  height: 50%;
}

.DayInfo_Container__xVsh_ {
  font-size: 1rem;
  font-weight: 700;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(6, 1fr);
  align-items: start;
  grid-template-areas:
    "sunrise sunrise sunrise temp  temp  temp"
    "sunset  sunset  sunset  feelt feelt feelt"
    "clouds  clouds  wind    wind  wind  wind"
    "desc    desc    desc    desc  desc  logo ";
}

.DayInfo_Sunrise__2fwhA {
  color: var(--color-primary);
  grid-area: sunrise;
  justify-self: start;
  transition: color 250ms;
}

.DayInfo_Sunset__32YHA {
  color: var(--color-primary);
  grid-area: sunset;
  justify-self: start;
  transition: color 250ms;
}

.DayInfo_Temp__2KzU0 {
  grid-area: temp;
  justify-self: end;
  font-size: 1.25em;
  transition: color 250ms;
}

.DayInfo_FeelTemp__MYpP6 {
  grid-area: feelt;
  align-self: start;
  justify-self: end;
  font-size: 0.8em;
  transition: color 250ms;
}

.DayInfo_Clouds__2Aely {
  grid-area: clouds;
  justify-self: start;
  transition: color 250ms;
}

.DayInfo_Wind__26kNn {
  grid-area: wind;
  justify-self: end;
  transition: color 250ms;
}

.DayInfo_WindArrow__1ol0n {
  display: inline-block;
  padding: 0;
  transition: transform 250ms;
}

.DayInfo_Description__6oyn6 {
  grid-area: desc;
  justify-self: start;
}

.DayInfo_Description__6oyn6 p {
  margin: 0 0.2rem;
}

.DayInfo_Logo__3OWr9 {
  grid-area: logo;
  justify-self: end;
  align-self: start;
  width: 3rem;
}

.DaysInfo_Container__1MSGt {
  /* Aspect ratio hack */
  position: relative;
  width: 100%;
  padding-top: 80%;
}

.DaysInfo_Diagram__3gEHj {
  position: absolute;
  top: 0;
  left: 0;
}

.DaysInfo_Caption__BqS9E {
  padding: 0.2rem;
  margin: 0.2rem;
  border: solid 1px  var(--color-neutral);
  border-radius: 3px;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
}

.DaysInfo_Tooltip__WTM9M {
  position: absolute;
  background-color: var(--color-white);
  border: solid 1px var(--color-light);
  color: var(--color-dark);
  padding: 0.5rem;
  box-shadow: 1px 1px 3px #9999;
  border-radius: 3px;
}

.DaysInfo_TooltipHeader__3Lsvg {
  margin: 0;
  padding: 0 0 0.5rem 0;
}

.DaysInfo_TooltipData__2WHBw {
  font-weight: 500;
}

.WeatherInfo_Containers__1_8bQ {
  margin: 0.5rem auto;
}

.WeatherInfo_ScreenLoadersContainer__1Vh3H {
  width: 14rem;
  height: 22rem;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(7, 1fr);
  align-items: start;
  grid-template-areas:
    ".      icon-1 icon-2 icon-3 icon-4 icon-5 .    "
    "graph  graph  graph  graph  graph  graph  graph"
    "graph  graph  graph  graph  graph  graph  graph"
    "graph  graph  graph  graph  graph  graph  graph"
    "graph  graph  graph  graph  graph  graph  graph"
    "legend legend legend legend legend legend legend"
    "legend legend legend legend legend legend legend"
    "sunr   sunr   sunr   temp   temp   temp   temp  "
    "suns   suns   suns   .      feel   feel   feel  "
    "cloud  cloud  wind   wind   wind   wind   wind  "
    "desc   desc   desc   desc   desc   icon-6 icon-6"
    "desc   desc   desc   desc   desc   icon-6 icon-6";
}

.WeatherInfo_GridItem-0__2GSNa {
  grid-area: icon-1;
}

.WeatherInfo_GridItem-1__zBN5Z {
  grid-area: icon-2;
}

.WeatherInfo_GridItem-2__3-o0R {
  grid-area: icon-3;
}

.WeatherInfo_GridItem-3__3DJvc {
  grid-area: icon-4;
}

.WeatherInfo_GridItem-4__1k0ut {
  grid-area: icon-5;
}

.WeatherInfo_GridItem-5__WOI8B {
  grid-area: graph;
}

.WeatherInfo_GridItem-6__23OoX {
  grid-area: legend;
}

.WeatherInfo_GridItem-7__2Hz1J {
  grid-area: sunr;
}

.WeatherInfo_GridItem-8__2AHdN {
  grid-area: temp;
}

.WeatherInfo_GridItem-9__OTt5E {
  grid-area: suns;
}

.WeatherInfo_GridItem-10__3COMT {
  grid-area: feel;
}

.WeatherInfo_GridItem-11__HZsM_ {
  grid-area: cloud;
}

.WeatherInfo_GridItem-12__2kNWG {
  grid-area: wind;
}

.WeatherInfo_GridItem-13__LAPcS {
  grid-area: desc;
}

.WeatherInfo_GridItem-14__1rbLR {
  grid-area: icon-6;
}


.DefaultButton_Button__1fvym {
  min-height: 48px;
  margin: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  outline: none;
  border: solid 1px;
  border-radius: 12px;
  box-shadow: 1px 1px 3px #9999;
  transition: color 250ms ease, background-color 250ms ease;
  cursor: pointer;
}

.DefaultButton_Button__1fvym.DefaultButton_Active__1MNZ9,
.DefaultButton_Button__1fvym:active {
  box-shadow: inset 1px 1px #9999;
}

.DefaultButton_Default__3E7Es {
  font-weight: 700;
  background-color: var(--color-white);
  border-color: var(--color-dark);
  color: var(--color-dark);
}

.DefaultButton_Default__3E7Es:hover,
.DefaultButton_Default__3E7Es:focus {
  background-color: var(--color-dark);
  color: var(--color-white);
}

.DefaultButton_Highlight__XZo5H {
  font-weight: 700;
  background-color: var(--color-white);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.DefaultButton_Highlight__XZo5H:hover,
.DefaultButton_Highlight__XZo5H:focus {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.DefaultButton_Danger__2B77i {
  font-weight: 700;
  background-color: var(--color-white);
  border-color: var(--color-danger);
  color: var(--color-danger);
}

.DefaultButton_Danger__2B77i:hover,
.DefaultButton_Danger__2B77i:focus {
  background-color: var(--color-danger);
  color: var(--color-white);
}

.DefaultButton_Disabled__3k_4L {
  cursor: not-allowed;
  box-shadow: none;
  background-color: var(--color-white);
  border-color: var(--color-neutral);
  color: var(--color-neutral);
}

.DefaultButton_Disabled__3k_4L.DefaultButton_Active__1MNZ9,
.DefaultButton_Disabled__3k_4L:active {
  box-shadow: none;
}

.Question_Controls__2I0H7 {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.Backdrop_Backdrop__3j6VK {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0.3) 50%, rgba(0, 0, 0, 0.6) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.09), rgba(0, 255, 0, 0.04), rgba(0, 0, 255, 0.09));
  background-size: 100% 2px, 3px 100%;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  opacity: 0;
  animation: Backdrop_show__20BR3 0.2s forwards;
}

@keyframes Backdrop_show__20BR3 {
  100% {
    opacity: 1;
  }
}

.Search_Input__2h1qy {
  position: relative;
  height: 1.5rem;
}

.Search_Search__2SQmI {
  position: absolute;
  inset: 0;
  width: 100%;
  border: none;
  border-bottom: solid 1px var(--color-neutral);
  color: var(--color-dark);
  caret-color: var(--color-dark);
  padding: 0.3rem 0.5rem;
  outline: none;
  transition: border-bottom 250ms ease;
}

.Search_Search__2SQmI:focus {
  border-bottom: solid 2px var(--color-primary);
}

.Search_Search__2SQmI::-webkit-input-placeholder {
  color: var(--color-neutral);
}

.Search_Search__2SQmI:-ms-input-placeholder {
  color: var(--color-neutral);
}

.Search_Search__2SQmI::-ms-input-placeholder {
  color: var(--color-neutral);
}

.Search_Search__2SQmI::placeholder {
  color: var(--color-neutral);
}

.Search_Button__2MkKB,
.Search_Spinner__2IGLs {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.Search_Spinner__2IGLs {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.2rem 0;
}

.Search_Button__2MkKB {
  border: none;
  text-decoration: none;
  padding: 0.3rem 0.2rem;
  border-radius: 3px;
  color: var(--color-primary);
  background-color: transparent;
  transition: background 250ms ease, color 250ms ease, transform 100ms ease;
}

.Search_Button__2MkKB:active {
  transform: scale(0.85);
}

.Search_Button__2MkKB:disabled {
  color: var(--color-neutral);
  opacity: 0.5;
}

.Search_Button__2MkKB:focus,
.Search_Button__2MkKB:hover:not(:disabled) {
  border: none;
  outline: none;
  background-color: var(--color-primary);
  color: var(--color-white);
}

.Search_Results__1Mb6w {
  background-color: var(--color-white);
  margin: 0;
  padding: 0;
  position: absolute;
  right: -0.5rem;
  width: 300%;
  max-width: min(calc(var(--width-app-max) * 3 / 4), 75vw);
  top: 1.8rem;
  box-shadow: 0 2px 3px var(--color-neutral);
  border-radius: 3px 0 3px 3px;
  overflow: hidden;
  transition: transform 250ms ease;
  transform-origin: right -1rem;
}

.Search_Hidden__2lHPJ {
  transform: scale(0);
}

.Search_ResultItem__NNzJC {
  list-style: none;
  margin: 0;
  padding: 0.5rem 0.5rem;
  transition: color 250ms ease, background-color 250ms ease;
  cursor: pointer;
}

.Search_ResultItem__NNzJC:focus {
  outline: none;
}

.Search_SelectedItem__1DkcA {
  color: var(--color-white);
  background-color: var(--color-primary);
}

.Search_ResultIcon__2UzOf {
  padding-right: 0.5rem;
  color: var(--color-primary);
  transition: color 250ms ease, background-color 250ms ease;
}

.Search_SelectedItem__1DkcA .Search_ResultIcon__2UzOf {
  color: var(--color-white);
  background-color: var(--color-primary);
}

.Modal_Container__252G4 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 500;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 250ms ease-out;
}

.Modal_Hide__j6BYH {
  opacity: 0;
}

.Modal_Modal__1-5dN {
  max-width: 90vw;
  margin: 2rem;
  flex: 0 1 auto;
  border: 1px solid var(--color-neutral);
  box-shadow: 1px 1px 3px var(--color-dark);
  background-color: var(--color-white);
  border-radius: 3px;
  padding: 32px;
  transition: transform 350ms cubic-bezier(0.27, 1.02, 0.99, 1.15);
  position: relative;
}

.Modal_TranslateUp__3dKAF {
  transform: translateY(-100vh);
}

.Modal_CloseBtn__34fid {
  position: absolute;
  top: 2px;
  right: 2px;
  margin: 0;
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  outline: none;
  border: none;
  background-color: var(--color-white);
  transition: background-color 250ms ease, transform 100ms ease;
}

.Modal_CloseBtn__34fid:active {
  transform: scale(0.8);
}

.Modal_BtnContent__261HH {
  position: relative;
  margin: auto;
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: transparent;
}

.Modal_BtnContent__261HH::before,
.Modal_BtnContent__261HH::after {
  display: block;
  width: 24px;
  height: 2px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--color-danger);
  transition: background-color 250ms ease;
}

.Modal_BtnContent__261HH::before {
  transform: translate(-50%, -50%) rotate(135deg);
}

.Modal_BtnContent__261HH::after {
  transform: translate(-50%, -50%) rotate(-135deg);
}

.Modal_CloseBtn__34fid:hover {
  background-color: var(--color-danger);
}

.Modal_CloseBtn__34fid:hover .Modal_BtnContent__261HH::before,
.Modal_CloseBtn__34fid:hover .Modal_BtnContent__261HH::after {
  background-color: var(--color-white);
}

@keyframes Modal_show__2xQp2 {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes Modal_slideDown__3AKx6 {
  from { transform: translateY(-100vh); }
  to { transform: translateY(0); }
}

.CityItem_ListItem__Pj6re {
  padding: 0.1rem;
  border: solid 1px #fff;
  border-radius: 3px;
  transition: all 250ms ease;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.CityItem_ItemSelected__2hIZm {
  border: solid 1px var(--color-primary);
}

.CityItem_ItemSelected__2hIZm:focus {
  outline: none;
}

.CityItem_Button__1EQrF {
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  text-decoration: none;
  padding: 0.4rem;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-color: transparent;
  transition: background 250ms ease, color 250ms ease, transform 250ms ease;
}

.CityItem_ButtonActive__1COQR {
  color: var(--color-primary);
}

.CityItem_ButtonDefault__1vW1L {
  color: var(--color-neutral);
}

.CityItem_ButtonDanger__1BUw3 {
  color: var(--color-danger);
}

.CityItem_Button__1EQrF.CityItem_Active__OjXDD,
.CityItem_Button__1EQrF:active {
  transform: scale(0.8);
}

.CityItem_Button__1EQrF:focus,
.CityItem_Button__1EQrF:hover:not(:disabled) {
  border: none;
  outline: none;
  color: var(--color-white);
}

.CityItem_ButtonActive__1COQR:focus,
.CityItem_ButtonActive__1COQR:hover:not(:disabled) {
  background-color: var(--color-primary);
}

.CityItem_ButtonDefault__1vW1L:focus,
.CityItem_ButtonDefault__1vW1L:hover:not(:disabled) {
  background-color: var(--color-neutral);
}

.CityItem_ButtonDanger__1BUw3:focus,
.CityItem_ButtonDanger__1BUw3:hover:not(:disabled) {
  background-color: var(--color-danger);
}

.CityItem_Text__kQBGC {
  display: inline-block;
  color: var(--color-dark);
  padding: 0.4rem 0.4rem 0.2rem;
  overflow-wrap: break-word;
  overflow: auto;
  flex: 1 1 auto;
}

.CityItem_Spinner__2W4VP {
  width: 28px;
  height: 28px;
}

.CitiesList_Container__2vQK6 {
  padding: 0;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;
  min-height: 10rem;
}

.CitiesList_Header__f3DOE {
  margin: 0.2rem 0.3rem;
  flex: 0 0 auto;
}

.CitiesList_List__qQgqP {
  flex: 1 1 auto;
  box-sizing: border-box;
  margin: 0;
  padding: 0.2rem 0;
  list-style: none;
  overflow: hidden;
}

.CitiesList_List__qQgqP:focus {
  outline: none;
}

:root {
  --component-sidedrawer-width: 15rem;
}

.SideDrawer_Switcher__1aCId {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transition: all 0.2s ease-out;
}

.SideDrawer_SwitcherDrawerOpened___Z4WH {
  right: 15rem;
  right: var(--component-sidedrawer-width);
}

.SideDrawer_Container__2S1Av {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 15rem;
  width: var(--component-sidedrawer-width);
  max-width: 85%;
  height: 100%;
  transition: right 0.2s ease-out;
  background-color: var(--color-white);
  box-shadow: var(--app-components-shadow);
}

.SideDrawer_SideDrawerClosed__2OhD3 {
  right: calc(-1 * 15rem);
  right: calc(-1 * var(--component-sidedrawer-width));
}

/*
TODO: Posible bug when location list makes sidedrawer bigger than screenY
 */
.SideDrawer_Controls__fwOZy {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 10;
}

.SideDrawer_Search__38zXW,
.SideDrawer_CitiesList__35Lbh,
.SideDrawer_LocsInfo__33wHA {
  margin: 0.5rem;
}

.SideDrawer_Search__38zXW,
.SideDrawer_LocsInfo__33wHA {
  flex: 0 0 auto;
}

.SideDrawer_CitiesList__35Lbh {
  flex: 1 1 auto;
}

@media only screen and (min-width: 40rem) {
  .SideDrawer_Container__2S1Av {
    position: static;
    position: initial;
    height: 100%;
  }

  .SideDrawer_Switcher__1aCId {
    display: none;
  }

  .SideDrawer_Controls__fwOZy {
    z-index: auto;
    z-index: initial;
  }
}

.Message_Container__15phw {
  position: relative;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin: 0.2rem;
  padding: 0.5rem 2.2rem 0.2rem 0.5rem;
  border-radius: 5px;
  box-shadow: 0 5px 10px #5555;
  background-color: #fff;
  transform-origin: top center;
  animation-fill-mode: forwards;
}

.Message_open__J72Ys {
  flex-grow: 1;
  animation-name: Message_open__J72Ys;
}

.Message_close__3zpeX {
  animation-name: Message_close__3zpeX;
}

.Message_Highlight__29jAz {
  color: var(--color-dark);
  border: solid 2px var(--color-primary);
  background-color: rgba(207, 241, 244, 0.7);
}

.Message_Danger__2VTl7 {
  color: var(--color-dark);
  border: solid 2px var(--color-danger);
  background-color: rgba(246, 218, 226, 0.7);
}

.Message_Default__3Ge4e {
  color: var(--color-dark);
  border: solid 2px var(--color-neutral);
  background-color: rgba(239, 239, 241, 0.7);
}

.Message_Container__15phw > strong::after {
  content: " ";
}

.Message_Button__28ife {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.4rem;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
}

@keyframes Message_open__J72Ys {
  from {
    transform: scale(0, 0);
    opacity: 0;
  }

  20% {
    transform: scale(1, 0.2);
    opacity: 1;
  }

  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes Message_close__3zpeX {
  from {
    transform: scale(1, 1);
    opacity: 1;
  }

  80% {
    transform: scale(1, 0.2);
    opacity: 1;
  }

  to {
    transform: scale(0, 0);
    opacity: 0;
  }
}

.About_Container__DjhoG {
  max-width: 32rem;
}

.About_Main__2DeY9 {
  margin-top: 1rem;
}

.About_Main__2DeY9 p {
  margin: 0.5rem 0;
}

.About_Main__2DeY9 a {
  text-decoration: none;
  font-weight: 700;
  color: var(--color-primary);
  transition: transform 0.2s, color 0.2s;
}

.About_Main__2DeY9 a:visited {
  color: #55959a;
}

.About_Main__2DeY9 a:hover,
.About_Main__2DeY9 a:focus {
  outline: none;
  text-decoration: underline;
  color: #30e8f6;
}

.App_App__16ZpL {
  --app-components-shadow: 0 5px 10px #5555;

  position: fixed;
  margin: 0 auto auto;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: stretch;
}

.App_MainView__1tHu3 {
  flex: 1 1 auto;
  position: relative;
  max-width: 100%;
  height: 100vh;
  background-color: var(--color-sea);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5rem;
  box-sizing: border-box;
}

.App_Map__aJBoe {
  --app-map-maxheight: calc(100vh - 6rem);

  /*
    Max width relative to max height:
    100vh = maxW/svg-aspect-ratio + DateHandler-height
    maxW = max-height * svg-aspect-ratio
  */
  max-width: calc(var(--app-map-maxheight) * var(--map-container-aspect-ratio));
  max-height: var(--app-map-maxheight);
  width: 100%;
  flex: 0 1 auto;
  -webkit-filter: drop-shadow(var(--app-components-shadow));
          filter: drop-shadow(var(--app-components-shadow));
  margin: 0;
}

.App_DateHandler__5SvJ5 {
  width: 14rem;
  height: 3rem;
  color: var(--color-dark);
  background-color: var(--color-white);
  border: solid 1px var(--color-light);
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: var(--app-components-shadow);
  flex: 0 0 auto;
}

.App_SideDrawer__If2iM {
  flex: 0 1 auto;
}

.App_Messages__3onsN {
  margin: 0.5rem;
  position: fixed;
  top: 0;
  left: 15vw;
  right: 15vw;
  max-width: 70vw;
}

.App_AboutBtn__3iCao {
  position: fixed;
  color: rgb(0, 122, 162);
  background-color: rgba(255, 255, 255, 0.17);
  letter-spacing: 0.1rem;
  border-radius: 3px;
  box-shadow: rgba(70, 101, 110, 0.3) 1px 1px 3px;
  margin: 0.5rem;
  padding: 0.2rem 0.5rem;
  bottom: 0;
  left: 0;
  transition: transform 0.2s, color 0.2s;
}

.App_AboutBtn__3iCao:hover,
.App_AboutBtn__3iCao:focus {
  transform: scale(1.1);
  color: rgb(72, 212, 55);
}

.App_AboutBtnActive__2noln {
  transform: scale(0.9);
}

.App_AboutBtnActive__2noln:hover,
.App_AboutBtnActive__2noln:focus {
  transform: scale(0.9);
}

