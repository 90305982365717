:root {
  --component-sidedrawer-width: 15rem;
}

.Switcher {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transition: all 0.2s ease-out;
}

.SwitcherDrawerOpened {
  right: var(--component-sidedrawer-width);
}

.Container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: var(--component-sidedrawer-width);
  max-width: 85%;
  height: 100%;
  transition: right 0.2s ease-out;
  background-color: var(--color-white);
  box-shadow: var(--app-components-shadow);
}

.SideDrawerClosed {
  right: calc(-1 * var(--component-sidedrawer-width));
}

/*
TODO: Posible bug when location list makes sidedrawer bigger than screenY
 */
.Controls {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 10;
}

.Search,
.CitiesList,
.LocsInfo {
  margin: 0.5rem;
}

.Search,
.LocsInfo {
  flex: 0 0 auto;
}

.CitiesList {
  flex: 1 1 auto;
}

@media only screen and (min-width: 40rem) {
  .Container {
    position: initial;
    height: 100%;
  }

  .Switcher {
    display: none;
  }

  .Controls {
    z-index: initial;
  }
}
