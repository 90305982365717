.Container {
  padding: 0;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;
  min-height: 10rem;
}

.Header {
  margin: 0.2rem 0.3rem;
  flex: 0 0 auto;
}

.List {
  flex: 1 1 auto;
  box-sizing: border-box;
  margin: 0;
  padding: 0.2rem 0;
  list-style: none;
  overflow: hidden;
}

.List:focus {
  outline: none;
}
