
.Button {
  min-height: 48px;
  margin: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  outline: none;
  border: solid 1px;
  border-radius: 12px;
  box-shadow: 1px 1px 3px #9999;
  transition: color 250ms ease, background-color 250ms ease;
  cursor: pointer;
}

.Button.Active,
.Button:active {
  box-shadow: inset 1px 1px #9999;
}

.Default {
  font-weight: 700;
  background-color: var(--color-white);
  border-color: var(--color-dark);
  color: var(--color-dark);
}

.Default:hover,
.Default:focus {
  background-color: var(--color-dark);
  color: var(--color-white);
}

.Highlight {
  font-weight: 700;
  background-color: var(--color-white);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.Highlight:hover,
.Highlight:focus {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.Danger {
  font-weight: 700;
  background-color: var(--color-white);
  border-color: var(--color-danger);
  color: var(--color-danger);
}

.Danger:hover,
.Danger:focus {
  background-color: var(--color-danger);
  color: var(--color-white);
}

.Disabled {
  cursor: not-allowed;
  box-shadow: none;
  background-color: var(--color-white);
  border-color: var(--color-neutral);
  color: var(--color-neutral);
}

.Disabled.Active,
.Disabled:active {
  box-shadow: none;
}
