.MutableButton {
  box-shadow: 1px 1px 3px #9999;
  background-color: #fffe;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  cursor: pointer;
}

.MutableButton:focus {
  box-shadow: 1px 1px 3px var(--color-primary);
}

.MutableButton.Disabled {
  cursor: not-allowed;
}

.MutableButton.Active,
.MutableButton:active:not(.Disabled) {
  box-shadow: inset 1px 1px #999;
}

.MutableButtonContent {
  position: relative;
}

.MutableButtonContent,
.MutableButtonContent::before,
.MutableButtonContent::after {
  display: inline-block;
  width: 50%;
  height: 2px;
  transition: all 0.5s ease-out;
}

.MutableButtonContent::before,
.MutableButtonContent::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.Highlight,
.Highlight::before,
.Highlight::after {
  background-color: var(--color-primary);
}

.Danger,
.Danger::before,
.Danger::after {
  background-color: var(--color-danger);
}

.MutableButtonContent.Disabled,
.MutableButtonContent.Disabled::before,
.MutableButtonContent.Disabled::after {
  background-color: var(--color-neutral);
}

/* Burger Button */

.BurgerButton::before {
  top: -8px;
}

.BurgerButton::after {
  top: 8px;
}

.MutableButton:focus .BurgerButton::before,
.MutableButton:hover .BurgerButton::before {
  top: -12px;
}

.MutableButton:focus .BurgerButton::after,
.MutableButton:hover .BurgerButton::after {
  top: 12px;
}

.MutableButton:hover .Disabled.BurgerButton::before {
  top: -8px;
}

.MutableButton:hover .Disabled.BurgerButton::after {
  top: 8px;
}

/* Close Button */
.CloseButton,
.CloseButton.Disabled {
  background-color: transparent;
}

.CloseButton::before {
  top: 0;
  transform: rotate(135deg);
}

.CloseButton::after {
  top: 0;
  transform: rotate(-135deg);
}

.MutableButton:focus .CloseButton,
.MutableButton:hover .CloseButton {
  transform: scale(1.2);
}

.MutableButton:hover .Disabled.CloseButton {
  transform: scale(1);
}

/* Left Button */
.LeftButton,
.LeftButton.Disabled {
  background-color: transparent;
}

.LeftButton::before {
  top: 0;
  transform: rotate(45deg);
  transform-origin: left;
}

.LeftButton::after {
  top: 0;
  transform: rotate(-45deg);
  transform-origin: left;
}

.MutableButton:focus .LeftButton,
.MutableButton:hover .LeftButton {
  transform: scale(1.2);
}

.MutableButton:hover .Disabled.LeftButton {
  transform: scale(1);
}

/* Right Button */
.RightButton,
.RightButton.Disabled {
  background-color: transparent;
}

.RightButton::before {
  top: 0;
  transform: rotate(45deg);
  transform-origin: right;
}

.RightButton::after {
  top: 0;
  transform: rotate(-45deg);
  transform-origin: right;
}

.MutableButton:focus .RightButton,
.MutableButton:hover .RightButton {
  transform: scale(1.2);
}

.MutableButton:hover .Disabled.RightButton {
  transform: scale(1);
}
