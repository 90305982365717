.Reseters {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  background-color: inherit;
  transition: transform 250ms;
}
