.Container {
  max-width: 32rem;
}

.Main {
  margin-top: 1rem;
}

.Main p {
  margin: 0.5rem 0;
}

.Main a {
  text-decoration: none;
  font-weight: 700;
  color: var(--color-primary);
  transition: transform 0.2s, color 0.2s;
}

.Main a:visited {
  color: #55959a;
}

.Main a:hover,
.Main a:focus {
  outline: none;
  text-decoration: underline;
  color: #30e8f6;
}
