.App {
  --app-components-shadow: 0 5px 10px #5555;

  position: fixed;
  margin: 0 auto auto;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: stretch;
}

.MainView {
  flex: 1 1 auto;
  position: relative;
  max-width: 100%;
  height: 100vh;
  background-color: var(--color-sea);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5rem;
  box-sizing: border-box;
}

.Map {
  --app-map-maxheight: calc(100vh - 6rem);

  /*
    Max width relative to max height:
    100vh = maxW/svg-aspect-ratio + DateHandler-height
    maxW = max-height * svg-aspect-ratio
  */
  max-width: calc(var(--app-map-maxheight) * var(--map-container-aspect-ratio));
  max-height: var(--app-map-maxheight);
  width: 100%;
  flex: 0 1 auto;
  filter: drop-shadow(var(--app-components-shadow));
  margin: 0;
}

.DateHandler {
  width: 14rem;
  height: 3rem;
  color: var(--color-dark);
  background-color: var(--color-white);
  border: solid 1px var(--color-light);
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: var(--app-components-shadow);
  flex: 0 0 auto;
}

.SideDrawer {
  flex: 0 1 auto;
}

.Messages {
  margin: 0.5rem;
  position: fixed;
  top: 0;
  left: 15vw;
  right: 15vw;
  max-width: 70vw;
}

.AboutBtn {
  position: fixed;
  color: rgb(0, 122, 162);
  background-color: rgba(255, 255, 255, 0.17);
  letter-spacing: 0.1rem;
  border-radius: 3px;
  box-shadow: rgba(70, 101, 110, 0.3) 1px 1px 3px;
  margin: 0.5rem;
  padding: 0.2rem 0.5rem;
  bottom: 0;
  left: 0;
  transition: transform 0.2s, color 0.2s;
}

.AboutBtn:hover,
.AboutBtn:focus {
  transform: scale(1.1);
  color: rgb(72, 212, 55);
}

.AboutBtnActive {
  transform: scale(0.9);
}

.AboutBtnActive:hover,
.AboutBtnActive:focus {
  transform: scale(0.9);
}
