.Containers {
  margin: 0.5rem auto;
}

.ScreenLoadersContainer {
  width: 14rem;
  height: 22rem;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(7, 1fr);
  align-items: start;
  grid-template-areas:
    ".      icon-1 icon-2 icon-3 icon-4 icon-5 .    "
    "graph  graph  graph  graph  graph  graph  graph"
    "graph  graph  graph  graph  graph  graph  graph"
    "graph  graph  graph  graph  graph  graph  graph"
    "graph  graph  graph  graph  graph  graph  graph"
    "legend legend legend legend legend legend legend"
    "legend legend legend legend legend legend legend"
    "sunr   sunr   sunr   temp   temp   temp   temp  "
    "suns   suns   suns   .      feel   feel   feel  "
    "cloud  cloud  wind   wind   wind   wind   wind  "
    "desc   desc   desc   desc   desc   icon-6 icon-6"
    "desc   desc   desc   desc   desc   icon-6 icon-6";
}

.GridItem-0 {
  grid-area: icon-1;
}

.GridItem-1 {
  grid-area: icon-2;
}

.GridItem-2 {
  grid-area: icon-3;
}

.GridItem-3 {
  grid-area: icon-4;
}

.GridItem-4 {
  grid-area: icon-5;
}

.GridItem-5 {
  grid-area: graph;
}

.GridItem-6 {
  grid-area: legend;
}

.GridItem-7 {
  grid-area: sunr;
}

.GridItem-8 {
  grid-area: temp;
}

.GridItem-9 {
  grid-area: suns;
}

.GridItem-10 {
  grid-area: feel;
}

.GridItem-11 {
  grid-area: cloud;
}

.GridItem-12 {
  grid-area: wind;
}

.GridItem-13 {
  grid-area: desc;
}

.GridItem-14 {
  grid-area: icon-6;
}
