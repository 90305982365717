.Container {
  width: 100%;
  border-bottom: solid 1px var(--color-neutral);
}

.Header {
  font-size: 1.5rem;
  margin: 0.2rem 0.3rem;
  width: 100%;
  font-weight: 200;
  letter-spacing: 0.15rem;
}
